/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email: string): boolean {
  if (typeof email !== "string" || email.trim() === "") {
    console.warn("Invalid input: Email should be a non-empty string.");
    return false;
  }
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  try {
    const isValid = reg.test(email);
    if (!isValid) {
      console.warn("Invalid email format.");
    }
    return isValid;
  } catch (error) {
    console.error("An error occurred while validating the email:", error);
    return false;
  }
}
/**
 * Validates whether a given password is valid.
 * @param password The password string to be validated.
 * @returns A boolean indicating whether the password is valid or not.
 */
export function validPassword(password: string | number): boolean {
  if (typeof password === 'string' && password.trim() === "") {
    console.warn("Invalid input: Password should be a non-empty.");
    return false;
  }
  const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
  try {
    const isValid = typeof password === 'number' ? passwordRegex.test(password.toString()) : passwordRegex.test(password);
    if (!isValid) {
      console.warn("Invalid email format.");
    }
    return isValid;
  } catch (error) {
    console.error("An error occurred while validating the email:", error);
    return false;
  }
  
}

/**
 * Validates the validity of an email verification code.
 * @param code The verification code
 * @returns A boolean indicating whether the code is valid or not. A valid code consists of 4 to 6 digits.
 */
export function validVerifyEmailCode(code: string | number) {
  const reg = /^\d{4,6}$/;
  return typeof code === 'number' ? reg.test(code.toString()) : reg.test(code);
}

// export function positiveFloatFixedReg(_toFixed: number): RegExp {
//   return new RegExp(
//     `\\D*((([1-9]\\d*(\\.\\d{0,${_toFixed}})?|0{1}(\\.\\d{0,${_toFixed}})?))?).*`,
//     "g"
//   );
// }


